import { useState, useEffect, useRef } from 'react';
import {
  DateBlock,
  DateSettingsBlock,
  DateSettingsDeleteIcon,
  DateSettingsIcon,
  FilterItem,
  FilterList,
  FilterMenuContainer,
  Plus,
  SearchButton,
  SearchInput,
  SelectInput,
} from './FilterMenu.styled';
import DateSettings from '../DateSettings/DateSettings';
import { selectUserType } from '../../../redux/selectors';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

const FilterMenu = ({
  selectedProject,
  handleProjectChange,
  handleColorChange,
  selectedColor,
  selectedStatus,
  handleStatusChange,
  setIsOpenFilter,
  openModal,
  DeleteDate,
  closeModal,
  setIsAfterDate,
  setIsBeforeDate,
  clickModal,
  isSettingsOpen,
  searchInputId,
  searchId,
  searchThreadId,
  searchInputUser,
  searchUser,
  searchThreadInputId,
}) => {
  const [openFilter, setOpenFilter] = useState(null);
  const [agent, setAgent] = useState('');
  const menuRef = useRef(null);
  const location = useLocation();

  const handleFilterToggle = id => {
    setOpenFilter(prevId => (prevId === id ? null : id));
    console.log(openFilter);
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpenFilter(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <FilterMenuContainer ref={menuRef}>
      <FilterList>
        {location.pathname === '/PaymentBot' ? (
          <FilterItem onClick={() => handleFilterToggle('date')}>
            Date
            <Plus />
            {openFilter === 'date' && (
              <DateBlock onClick={e => e.stopPropagation()}>
                <DateSettingsBlock>
                  <DateSettingsIcon
                    onClick={openModal}
                    title={'Выбрать дату'}
                  />
                  <DateSettingsDeleteIcon
                    onClick={DeleteDate}
                    title={'Удалить дату'}
                  />
                  {isSettingsOpen && (
                    <DateSettings
                      closeModal={closeModal}
                      title={'Выбрать дату'}
                      setIsAfterDate={setIsAfterDate}
                      setIsBeforeDate={setIsBeforeDate}
                      clickModal={clickModal}
                    />
                  )}
                </DateSettingsBlock>
              </DateBlock>
            )}
          </FilterItem>
        ) : (
          <>
            <FilterItem onClick={() => handleFilterToggle('project')}>
              Group
              <Plus />
              {openFilter === 'project' && (
                <SelectInput
                  title={'Выбрать проект'}
                  value={selectedProject}
                  onChange={handleProjectChange}
                  onClick={e => e.stopPropagation()}
                >
                  <option value="">All</option>
                  <option value="1">Gama</option>
                  <option value="2">Kent</option>
                  <option value="3">Cat</option>
                  <option value="4">R7</option>
                  <option value="5">Kometa</option>
                  <option value="6">Daddy</option>
                  <option value="7">Mers</option>
                  <option value="9">Arcada</option>
                </SelectInput>
              )}
            </FilterItem>
            <FilterItem onClick={() => handleFilterToggle('color')}>
              Chat Color
              <Plus />
              {openFilter === 'color' && (
                <SelectInput
                  title={'Выбрать цвет'}
                  value={selectedColor}
                  onChange={handleColorChange}
                  onClick={e => e.stopPropagation()}
                >
                  <option value="">All</option>
                  <option value="green">Green</option>
                  <option value="yellow">Yellow</option>
                  <option value="red">Red</option>
                </SelectInput>
              )}
            </FilterItem>
            <FilterItem onClick={() => handleFilterToggle('userType')}>
              User Type
              <Plus />
              {openFilter === 'userType' && (
                <SelectInput
                  title={'Выбрать вид'}
                  value={selectedStatus}
                  onChange={handleStatusChange}
                  onClick={e => e.stopPropagation()}
                >
                  <option value="">All</option>
                  <option value="vip">Vip</option>
                  <option value="other">Other</option>
                </SelectInput>
              )}
            </FilterItem>
            <FilterItem onClick={() => handleFilterToggle('date')}>
              Date
              <Plus />
              {openFilter === 'date' && (
                <DateBlock onClick={e => e.stopPropagation()}>
                  <DateSettingsBlock>
                    <DateSettingsIcon
                      onClick={openModal}
                      title={'Выбрать дату'}
                    />
                    <DateSettingsDeleteIcon
                      onClick={DeleteDate}
                      title={'Удалить дату'}
                    />
                    {isSettingsOpen && (
                      <DateSettings
                        closeModal={closeModal}
                        title={'Выбрать дату'}
                        setIsAfterDate={setIsAfterDate}
                        setIsBeforeDate={setIsBeforeDate}
                        clickModal={clickModal}
                      />
                    )}
                  </DateSettingsBlock>
                </DateBlock>
              )}
            </FilterItem>
            <FilterItem onClick={() => handleFilterToggle('agent')}>
              Agent
              <Plus />
              {openFilter === 'agent' && (
                <>
                  <SearchInput
                    placeholder="Введите имя агента..."
                    onChange={e => setAgent(e.target.value)}
                    onClick={e => e.stopPropagation()}
                  />
                  <SearchButton onClick={() => searchInputUser(agent)}>
                    +
                  </SearchButton>
                </>
              )}
            </FilterItem>
            {/* <FilterItem onClick={() => handleFilterToggle('id')}>
              Chat ID
              <Plus />
              {openFilter === 'id' && (
                <SearchInput
                  placeholder="Введите айди чата..."
                  onChange={searchInputId}
                  value={searchId}
                  onClick={e => e.stopPropagation()}
                />
              )}
            </FilterItem> */}
            <FilterItem onClick={() => handleFilterToggle('threadId')}>
              Chat ID
              <Plus />
              {openFilter === 'threadId' && (
                <SearchInput
                  placeholder="Введите айди чата..."
                  onChange={searchThreadInputId}
                  value={searchThreadId}
                  onClick={e => e.stopPropagation()}
                />
              )}
            </FilterItem>
          </>
        )}
      </FilterList>
    </FilterMenuContainer>
  );
};

export default FilterMenu;
