import { createAsyncThunk } from '@reduxjs/toolkit';
import { getPaymentBotMonthStat, getPaymentBotStat } from 'services/API';

export const getPaymentBotStatThunk = createAsyncThunk(
  'paymentBot/stat',
  async ({ token, dateFrom, dateTo }, thunkAPI) => {
    try {
      const response = await getPaymentBotStat(token, dateFrom, dateTo);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const getPaymentBotMonthStatThunk = createAsyncThunk(
  'paymentMonthBot/stat',
  async ({ token, dateFrom, dateTo }, thunkAPI) => {
    try {
      const response = await getPaymentBotMonthStat(token, dateFrom, dateTo);
      return response;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);
