import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { SlCalender } from 'react-icons/sl';
import { FiClock } from 'react-icons/fi';
import { IoNewspaperOutline } from 'react-icons/io5';
import { IoBagAddOutline } from 'react-icons/io5';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { MdCurrencyExchange } from 'react-icons/md';
import { FaUserSecret } from 'react-icons/fa6';
import { FaBook } from 'react-icons/fa';
import { IoLibraryOutline } from 'react-icons/io5';
import { IoStatsChartOutline } from 'react-icons/io5';
import { FaGlasses } from 'react-icons/fa6';
import { FaBoxArchive } from 'react-icons/fa6';
import { BiSupport } from 'react-icons/bi';
import { MdOutlinePayment } from 'react-icons/md';
import { FaMailBulk } from "react-icons/fa";
import { FiTable } from "react-icons/fi";


export const SideBarConteiner = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 101px;
  position: fixed;
  height: 100%;
  border-right: 1px solid #ccc;
  padding-top: 90px;
`;
export const ListConteiner = styled.div`
  /* margin-bottom: 270px; */
`;

export const TimeConteiner = styled.div`
  width: 37px;
  height: 32px;
  color: #b1b1b1;
  position: relative;
  padding-bottom: 90px;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    left: 5px;
    width: 26px;
    height: 1px;
    background-color: #b1b1b1;
  }
`;

export const ListSideBar = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ItemSideBar = styled.li`
  &:not(:last-child) {
    margin-bottom: 30px;
  }
`;

export const NavLinkStyled = styled(NavLink)`
  color: #b1b1b1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 50px;
  height: 50px;
  position: relative;

  &.active {
    box-shadow: 0px 4px 3.7px
      rgba(${props => (props.switcher ? '255, 255, 255' : '0, 0, 0')}, 0.06);
    border-radius: 6px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
`;

export const Calendar = styled(SlCalender)`
  width: 20px;
  height: 20px;
`;

export const Clock = styled(FiClock)`
  width: 20px;
  height: 20px;
`;

export const News = styled(IoNewspaperOutline)`
  width: 20px;
  height: 20px;
`;

export const Fraud = styled(FaUserSecret)`
  width: 20px;
  height: 20px;
`;

export const Tools = styled(IoBagAddOutline)`
  width: 20px;
  height: 20px;
`;

export const Person = styled(BsFillPersonLinesFill)`
  width: 20px;
  height: 20px;
`;

export const Currency = styled(MdCurrencyExchange)`
  width: 20px;
  height: 20px;
`;

export const VipEmails = styled(FaMailBulk)`
  width: 20px;
  height: 20px;
`;

export const Library = styled(IoLibraryOutline)`
  width: 20px;
  height: 20px;
`;

export const Qa = styled(BiSupport)`
  width: 20px;
  height: 20px;
`;

export const Payment = styled(MdOutlinePayment)`
  width: 20px;
  height: 20px;
`;

export const PaymentMonthStat = styled(FiTable)`
  width: 20px;
  height: 20px;
`;

export const Statistic = styled(IoStatsChartOutline)`
  width: 20px;
  height: 20px;
`;

export const Archive = styled(FaBoxArchive)`
  width: 20px;
  height: 20px;
`;

export const LanchContainer = styled.div`
  color: #b1b1b1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  width: 50px;
  height: 50px;
  position: relative;

  &.active {
    box-shadow: 0px 4px 3.7px rgba(0, 0, 0, 0.06);
    border-radius: 6px;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border-radius: 6px;
    }
  }
`;

export const DataBase = styled(FaBook)`
  width: 20px;
  height: 20px;
`;

export const QA = styled(FaGlasses)`
  width: 20px;
  height: 20px;
`;
